import '../scss/style.scss';

var hash = location.hash;

//  デバイス判定
var ua = navigator.userAgent;
var SP = false;
var Tab = false;
var PC = false;
if( ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 ) {
  // sp
  $('body').addClass('sp');
  SP = true;
} else if( ua.indexOf('iPad') > 0 || ua.indexOf('Macintosh') > -1 && 'ontouchend' in document || ua.indexOf('Android') > 0 ) {
  // tab
  $('body').addClass('tab');
  Tab = true;
} else {
  // pc
  $('body').addClass('pc');
  PC = true;
}

$(function(){
  $('.global-header').append('<div class="overlay" />');
  if ( $('.global-header--login')[0] ) {
    $(window).on('load scroll', function() {
      var st = $(window).scrollTop();
      var ht = $('.login-header').offset().top;
      if ( st > ht ) {
        $('.global-header--login').addClass('is-show');
      } else {
        $('.global-header--login').removeClass('is-show');
      }
    });
  }
  if ( $('.global-header').hasClass('is-absolute') ) {
    $(window).on('load scroll', function() {
      var st = $(window).scrollTop();
      var tp = $('.js-transform').offset().top;
      var cp = $('.js-change').offset().top;
      var sp = $('.js-show').offset().top;
      if ( st > tp ) {
        $('.global-header').addClass('is-transform');
      } else {
        $('.global-header').removeClass('is-transform');
      }
      if ( st > cp ) {
        $('.global-header').addClass('is-fixed').removeClass('is-absolute');
      } else {
        $('.global-header').removeClass('is-fixed').addClass('is-absolute');
      }
      if ( st > sp ) {
        $('.global-header').addClass('is-show');
      } else {
        $('.global-header').removeClass('is-show');
      }
    });
  }
  $('.global-nav__btn').on('click', function() {
    $(this).toggleClass('is-open');
    $(this).parent('.global-nav').find('.global-nav-contents').slideToggle();
  });
  if ( PC ) {
    $('.has-subpage').hover(function() {
      $(this).addClass('is-open');
      $(this).find('.sub-page-wrap').addClass('is-show');
      $('.overlay').addClass('is-show');
    }, function() {
      $(this).removeClass('is-open');
      $(this).find('.sub-page-wrap').removeClass('is-show');
      $('.overlay').removeClass('is-show');
    });
  }
  if ( Tab ) {
    $('.has-subpage').on('click', function() {
      $(this).toggleClass('is-open');
      $(this).find('.sub-page-wrap').toggleClass('is-show');
    });
  }
  if ( SP ) {
    $('.has-subpage').on('click', function() {
      $(this).toggleClass('is-open');
      $(this).find('.sub-page-wrap').slideToggle();
    });
  }
  $('.global-menu-list__item--search span').on('click', function() {
    $(this).toggleClass('is-open');
  });

  //スライダ
  if ( $('.top-pickup-slider')[0] ) {
    $('.top-pickup-slider').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      cssEase: 'ease-out',
      dots: true,
      prevArrow: $('.top-pickup__arrow--prev'),
      nextArrow: $('.top-pickup__arrow--next'),
      pauseOnHover: false,
      speed: 1500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    })
    .on('beforeChange',function(event, slick, currentSlide, nextSlide){
      if(nextSlide > 0 ) {
        $(this).slick('slickSetOption', 'autoplaySpeed', 3500);
      }
    });
  }

  // スムーススクロール
  $('a[href^="#"]:not(".noscroll")').on('click', function() {
    var href     = $(this).attr("href");
    var target   = $(href == "#" || href == "" ? "html" : href);
    scrollToAnker(target);
    return false;
   });
  if ( hash ) {//別ページからの遷移時に#タグがあった場合
    $('body,html').stop().scrollTop(0);
    setTimeout(function () {
      scrollToAnker(hash);
    }, 100);
  }
});

function scrollToAnker(hash) {
  var speed    = 500; // ミリ秒
  var target   = $(hash);
  var position = target.offset().top;
  $("body,html").stop().animate({scrollTop:position}, speed, "swing");
}
